import {defineComponent} from 'vue';
import {
  addUserCompanyAdmin,
  addUserCompanyManager,
  allUsersForCompany,
  deleteUserCompanyAdmin,
  updateUserCompanyAdmin, updateUserCompanyManager
} from '@/apiCalls/backOfficeApi';
import {translate} from '@/i18n';
import {mapGetters} from 'vuex';
import {
  sendEmailResetPasswordAdmin,
  sendEmailResetPasswordManager,
  requestPasswordResetEmail
} from "@/apiCalls/passwordReset";
import {
  resetMyApiKey,
  sendEmailResetApiKeyAdmin,
  sendEmailResetApiKeyManager
} from "@/apiCalls/apiKeyReset";
import {useToast} from 'vue-toast-notification';
import {copyMessage} from "@/utils/copyClipboard";

export default defineComponent({
  methods: {
    async usersForCompany({
                            userName,
                            email,
                            enabled,
                            phone,
                            position,
                            group,
                            place,
                            profiles,
                            sortName,
                            sortDirection
                          }: {
      userName?: string, email?: string, enabled?: boolean, phone?: string,
      position?: string, group?: string, place?: string, profiles?: string,
      sortName?: string, sortDirection?: string
    }) {
      const users = await allUsersForCompany(userName, email, enabled, phone, position, group, place, profiles, this.currentPage, this.perPage, sortName, sortDirection);
      this.totalPages = users.data.totalPages;
      this.totalElements = users.data.totalElements;
      this.contentUsers = users.data.content.map((user: any) => {
        return {...user};
      });
    },
    movePages(amount: number) {
      const newStartRow = (this.currentPage + amount);
      if (newStartRow >= 0) {
        this.currentPage = newStartRow;
        if (Object.values(this.contentSearch).some(value => value !== '')) {
          this.usersForCompany({...this.contentSearch, sortName: 'username', sortDirection: 'ASC'});
        } else {
          this.usersForCompany({sortName: 'username', sortDirection: 'ASC'});
        }
      }
    },
    translate,
    async updateUser(user: any, role: 'COMPANY_MANAGER' | 'COMPANY_USER', profilesCheck: {name: string}[]) {
      const profiles= this.profiles?.filter((elt: {name: string}) => profilesCheck?.some((profile:any) => profile.name === elt.name))?.map((elt: { name: string}) => elt.name);
      if (this.user.roles.includes('COMPANY_ADMIN')) {
        await updateUserCompanyAdmin(user.username, user.email, user.enabled, user.phone, user.function, user.group, user.place, role, user.userId, user.supervisor ? parseInt(user.supervisor.id) : undefined, profiles);
      } else if (this.user.roles.includes('COMPANY_MANAGER')) {
        await updateUserCompanyManager(user.username, user.email, user.enabled, user.phone, user.function, user.group, user.place, user.userId, this.profiles.filter((elt, i) => profilesCheck[i]).map((elt: {
          name: string
        }) => elt.name));
      }
      await this.usersForCompany({...this.contentSearch, sortName: 'username', sortDirection: 'ASC'});
    },
    async deleteUser(user: any) {
      await deleteUserCompanyAdmin(user.userId);
      await this.resetInputSearch();
      await this.usersForCompany({sortName: 'username', sortDirection: 'ASC'});
    },
    async sendEmailResetApiKey(userId: number) {
      const toast = useToast();
      if (this.user.id === userId) {
        const response = await resetMyApiKey();
        toast.success(translate('BACKOFFICE.USERS.RESET_API_KEY.NEW_API_KEY'));
        copyMessage(response.data.apiKey);
        return;
      } else if (this.user.roles.includes("COMPANY_ADMIN")) await sendEmailResetApiKeyAdmin(userId);
      else if (this.user.roles.includes("COMPANY_MANAGER")) await sendEmailResetApiKeyManager(userId);
      toast.success(translate('BACKOFFICE.USERS.RESET_API_KEY.SENDING_MAIL'));
    },
    async sendEmailResetPassword(userId: number) {
      if (this.user.id === userId) await requestPasswordResetEmail(this.user.email);
      else if (this.user.roles.includes("COMPANY_ADMIN")) await sendEmailResetPasswordAdmin(userId);
      else if (this.user.roles.includes("COMPANY_MANAGER")) await sendEmailResetPasswordManager(userId);
      const toast = useToast();
      toast.success(translate('BACKOFFICE.USERS.RESET_PASSWORD.SENDING_MAIL'));
    },
    async validateAddUser() {
      if (this.user.roles.includes('COMPANY_ADMIN')) {
        await addUserCompanyAdmin(this.contentAddModal?.username, this.contentAddModal?.email, true, this.contentAddModal?.phone, this.contentAddModal?.function, this.contentAddModal?.group, this.contentAddModal?.place, this.contentAddModal.isManager ? 'COMPANY_MANAGER' : 'COMPANY_USER', this.contentAddModal?.supervisor ? this.contentAddModal?.supervisor.id : undefined,
          this.profilesUser?.map((elt: {name: string}) => elt.name));
      } else if (this.user.roles.includes('COMPANY_MANAGER')) {
        await addUserCompanyManager(this.contentAddModal?.username, this.contentAddModal?.email, true, this.contentAddModal?.phone, this.contentAddModal?.function, this.contentAddModal?.group, this.contentAddModal?.place, this.contentAddModal?.supervisor ? this.contentAddModal?.supervisor.id : undefined,
          this.profilesUser?.map((elt: {name: string}) => elt.name));
      }
      await this.usersForCompany({...this.contentSearch, sortName: 'username', sortDirection: 'ASC'});
    },
    async searchUser(e: SubmitEvent) {
      e.preventDefault();
      this.currentPage = 0;
      await this.usersForCompany({...this.contentSearch, sortName: 'username', sortDirection: 'ASC'});
    },
    async resetSearch(e: Event) {
      e.preventDefault();
      this.currentPage = 0;
      this.contentSearch = {
        userName: '',
        email: '',
        phone: '',
        group: '',
        place: '',
        profiles: ''
      };
      await this.resetInputSearch();
      await this.usersForCompany({sortName: 'username', sortDirection: 'ASC'});
    },
    async resetInputSearch() {
      this.shouldReset = true; //modify the value to trigger the reinitialization
      await this.$nextTick(() => {
        this.shouldReset = false; //reinitialize the value after a tick
      });
    }
  },
  data: () => {
    return {
      totalPages: 1,
      perPage: 10,
      currentPage: 0,
      totalElements: 0,
      titleUsers: [
        'ID',
        'IDENTITY.NAME',
        'IDENTITY.EMAIL',
        'IDENTITY.PHONE_NUMBER',
        'BACKOFFICE.USERS.ACTIVE',
        'BACKOFFICE.USERS.GROUP',
        'BACKOFFICE.USERS.PLACE',
        'TABLE.ACTIONS'
      ].map(translate),
      contentUsers: [] as any,
      elementsPerPage: ['10', '20', '30'],
      filterUsers: ['companyId', 'companyName', 'function', 'isTwoFactorAuthenticationChecked', 'isTwoFactorAuthenticationRequiredByCompany', 'roles', 'twoFactorAuthenticationStatus', 'supervisor', 'profiles'] as any,
      contentAddModal: {} as {
        companyName: string,
        username: string,
        email: string,
        enabled?: boolean,
        phone: string,
        function: string,
        place: string,
        group: string,
        isManager: boolean,
        supervisor: {
          id: number,
          username: string
        },
        profiles: string[]
      },
      checkBoxRoleAddedUpdate: false,
      contentSearch: {
        userName: '',
        email: '',
        phone: '',
        group: '',
        place: '',
        profiles: ''
      } as {
        userName: string,
        email: string,
        phone: string,
        group: string,
        place: string,
        profiles: string
      },
      shouldReset: false,
      profiles: [],
      profilesUser: [] as any
    };
  },
  computed: {
    ...mapGetters(['user'])
  }
});
