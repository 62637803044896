import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, renderList as _renderList, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 3,
  class: "modal-add-user__advanced-user"
}
const _hoisted_2 = { class: "modal-add-user__advanced-user__checked" }
const _hoisted_3 = ["checked"]
const _hoisted_4 = { class: "modal-add-user__actions" }
const _hoisted_5 = { class: "modal-add-user__summary" }
const _hoisted_6 = { class: "modal-add-user__summary__title" }
const _hoisted_7 = { class: "modal-add-user__summary__informations" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "modal-add-user__profile" }
const _hoisted_10 = { class: "modal-add-user__profile__title" }
const _hoisted_11 = { class: "modal-add-user__profile__content" }
const _hoisted_12 = ["checked", "onInput"]
const _hoisted_13 = { class: "modal-add-user__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ResultCard = _resolveComponent("ResultCard")!
  const _component_CustomModal = _resolveComponent("CustomModal")!

  return (_openBlock(), _createBlock(_component_CustomModal, {
    open: _ctx.openModalFlag,
    "onUpdate:open": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.openModalFlag) = $event)),
    "custom-class": "modal-add-user",
    onCloseModal: _ctx.cancelAction
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ResultCard, { title: _ctx.title }, {
        default: _withCtx(() => [
          (_ctx.step)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.user)
                  ? (_openBlock(), _createBlock(_component_TextInput, {
                      key: 0,
                      label: _ctx.translate('BACKOFFICE.COMPANY'),
                      modelValue: _ctx.user.company.name,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.company.name) = $event)),
                      disabled: true
                    }, null, 8, ["label", "modelValue"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_TextInput, {
                  label: `${_ctx.translate('IDENTITY.NAME')}*`,
                  modelValue: _ctx.content.username,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.content.username) = $event)),
                  type: _ctx.NAME
                }, null, 8, ["label", "modelValue", "type"]),
                _createVNode(_component_TextInput, {
                  label: `${_ctx.translate('EMAIL')}*`,
                  modelValue: _ctx.content.email,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.content.email) = $event)),
                  type: _ctx.EMAIL
                }, null, 8, ["label", "modelValue", "type"]),
                _createVNode(_component_TextInput, {
                  label: _ctx.translate('PHONE'),
                  modelValue: _ctx.content.phone,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.content.phone) = $event))
                }, null, 8, ["label", "modelValue"]),
                _createVNode(_component_TextInput, {
                  label: _ctx.translate('BACKOFFICE.FUNCTION'),
                  modelValue: _ctx.content.function,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.content.function) = $event))
                }, null, 8, ["label", "modelValue"]),
                _createVNode(_component_Select, {
                  value: _ctx.content.place,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.content.place) = $event)),
                  options: _ctx.places?.map(({name}) => name),
                  values: _ctx.places?.map(({name}) => name),
                  title: _ctx.translate('BACKOFFICE.USERS.PLACE'),
                  class: "modal-add-user__select-supervisor",
                  "empty-option": true
                }, null, 8, ["value", "options", "values", "title"]),
                _createVNode(_component_Select, {
                  value: _ctx.content.group,
                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.content.group) = $event)),
                  options: _ctx.groups?.map(({name}) => name),
                  values: _ctx.groups?.map(({name}) => name),
                  title: _ctx.translate('BACKOFFICE.USERS.GROUP'),
                  class: "modal-add-user__select-supervisor",
                  "empty-option": true
                }, null, 8, ["value", "options", "values", "title"]),
                (_ctx.user?.roles.includes('COMPANY_MANAGER'))
                  ? (_openBlock(), _createBlock(_component_TextInput, {
                      key: 1,
                      label: _ctx.translate('BACKOFFICE.USERS.SUPERVISOR'),
                      modelValue: _ctx.supervisorName,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.supervisorName) = $event)),
                      disabled: true
                    }, null, 8, ["label", "modelValue"]))
                  : (_openBlock(), _createBlock(_component_Select, {
                      key: 2,
                      value: _ctx.supervisorId,
                      "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.supervisorId) = $event)),
                      options: _ctx.supervisors?.map(({username}) => username),
                      values: _ctx.supervisors?.map(({id}) => id),
                      title: _ctx.translate('BACKOFFICE.USERS.SUPERVISOR'),
                      class: "modal-add-user__select-supervisor",
                      "empty-option": true,
                      onValueSelected: _ctx.setSupervisorName,
                      onOptionSelected: _ctx.setSupervisorId
                    }, null, 8, ["value", "options", "values", "title", "onValueSelected", "onOptionSelected"])),
                (_ctx.user?.roles.includes('COMPANY_ADMIN'))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.translate('BACKOFFICE.ADVANCED_USER')), 1),
                      _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("input", {
                          type: "checkbox",
                          checked: _ctx.checkBox,
                          onInput: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.stateCheckBox && _ctx.stateCheckBox(...args)))
                        }, null, 40, _hoisted_3),
                        _createElementVNode("span", null, _toDisplayString(_ctx.translate('BACKOFFICE.ADVANCED_USER_MESSAGE')), 1)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_Button, {
                    label: _ctx.translate('BUTTONS.CANCEL'),
                    class: "modal-add-user__actions__cancel",
                    onClick: _ctx.cancelAction
                  }, null, 8, ["label", "onClick"]),
                  _createVNode(_component_Button, {
                    label: _ctx.translate('BUTTONS.CONFIRM_AND_CONTINUE'),
                    class: "modal-add-user__actions__validate",
                    onClick: _ctx.nextStep
                  }, null, 8, ["label", "onClick"])
                ])
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(`${_ctx.translate('SUMMARY')} :`), 1),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('BACKOFFICE.COMPANY')} : ${_ctx.user.company.name ? _ctx.user.company.name : '-'}`), 1),
                    _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('IDENTITY.NAME')} : ${_ctx.content.username ? _ctx.content.username : '-'}`), 1),
                    _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('EMAIL')} : ${_ctx.content.email ? _ctx.content.email : '-'}`), 1),
                    _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('PHONE')} : ${_ctx.content.phone ? _ctx.content.phone : '-'}`), 1),
                    (_ctx.user?.roles.includes('COMPANY_ADMIN'))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(`${_ctx.translate('BACKOFFICE.ADVANCED_USER')} : ${_ctx.checkBox ? _ctx.translate('YES') : _ctx.translate('NO')}`), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(`${_ctx.translate('BACKOFFICE.USERS.PROFILE_ASSIGNMENTS')} :`), 1),
                  _createElementVNode("div", _hoisted_11, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profiles, (profile, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                        _createElementVNode("input", {
                          class: "modal-add-user__profile__content__checkbox",
                          type: "checkbox",
                          checked: _ctx.checkedProfiles(index, profile?.name),
                          onInput: (e) => _ctx.stateCheckBoxProfile(e, index, profile?.name)
                        }, null, 40, _hoisted_12),
                        _createElementVNode("span", null, _toDisplayString(profile?.name), 1),
                        _createElementVNode("span", null, _toDisplayString(profile?.description), 1)
                      ], 64))
                    }), 128))
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_Button, {
                    label: _ctx.translate('BUTTONS.RETURN'),
                    class: "modal-add-user__actions__return",
                    onClick: _ctx.backFirstPage
                  }, null, 8, ["label", "onClick"]),
                  _createVNode(_component_Button, {
                    label: _ctx.translate('BUTTONS.CANCEL'),
                    class: "modal-add-user__actions__cancel",
                    onClick: _ctx.cancelAction
                  }, null, 8, ["label", "onClick"]),
                  _createVNode(_component_Button, {
                    label: _ctx.translate('BUTTONS.CONFIRM_AND_CONTINUE'),
                    class: "modal-add-user__actions__validate",
                    onClick: _ctx.validateAction
                  }, null, 8, ["label", "onClick"])
                ])
              ], 64))
        ]),
        _: 1
      }, 8, ["title"])
    ]),
    _: 1
  }, 8, ["open", "onCloseModal"]))
}