<template>
  <CustomModal v-model:open="openModalFlag" custom-class="modal-add-user" @close-modal="cancelAction">
    <ResultCard :title="title">
      <template v-if="step">
        <TextInput v-if="user" :label="translate('BACKOFFICE.COMPANY')"
                   v-model="user.company.name" :disabled="true"/>
        <TextInput :label="`${translate('IDENTITY.NAME')}*`"
                   v-model="content.username" :type="NAME"/>
        <TextInput :label="`${translate('EMAIL')}*`" v-model="content.email" :type="EMAIL"/>
        <TextInput :label="translate('PHONE')" v-model="content.phone"/>
        <TextInput :label="translate('BACKOFFICE.FUNCTION')" v-model="content.function"/>
        <Select v-model:value="content.place" :options="places?.map(({name}) => name)"
                :values="places?.map(({name}) => name)" :title="translate('BACKOFFICE.USERS.PLACE')"
                class="modal-add-user__select-supervisor" :value="content.place" :empty-option="true"/>
        <Select v-model:value="content.group" :options="groups?.map(({name}) => name)"
                :values="groups?.map(({name}) => name)" :title="translate('BACKOFFICE.USERS.GROUP')"
                class="modal-add-user__select-supervisor" :value="content.group" :empty-option="true"/>
        <TextInput v-if="user?.roles.includes('COMPANY_MANAGER')" :label="translate('BACKOFFICE.USERS.SUPERVISOR')"
                   v-model="supervisorName" :disabled="true"/>
        <Select v-else v-model:value="supervisorId" :options="supervisors?.map(({username}) => username)"
                :values="supervisors?.map(({id}) => id)" :title="translate('BACKOFFICE.USERS.SUPERVISOR')"
                class="modal-add-user__select-supervisor" :value="supervisorId" :empty-option="true"
                @value-selected="setSupervisorName" @option-selected="setSupervisorId"/>
        <div v-if="user?.roles.includes('COMPANY_ADMIN')" class="modal-add-user__advanced-user">
          <span>{{ translate('BACKOFFICE.ADVANCED_USER') }}</span>
          <div class="modal-add-user__advanced-user__checked">
            <input type="checkbox" :checked="checkBox" @input="stateCheckBox">
            <span>{{ translate('BACKOFFICE.ADVANCED_USER_MESSAGE') }}</span>
          </div>
        </div>
        <div class="modal-add-user__actions">
          <Button :label="translate('BUTTONS.CANCEL')" class="modal-add-user__actions__cancel" @click="cancelAction"/>
          <Button :label="translate('BUTTONS.CONFIRM_AND_CONTINUE')" class="modal-add-user__actions__validate"
                  @click="nextStep"/>
        </div>
      </template>
      <template v-else>
        <div class="modal-add-user__summary">
          <span class="modal-add-user__summary__title">{{ `${translate('SUMMARY')} :` }}</span>
          <div class="modal-add-user__summary__informations">
            <span>{{ `${translate('BACKOFFICE.COMPANY')} : ${user.company.name ? user.company.name : '-'}` }}</span>
            <span>{{ `${translate('IDENTITY.NAME')} : ${content.username ? content.username : '-'}` }}</span>
            <span>{{ `${translate('EMAIL')} : ${content.email ? content.email : '-'}` }}</span>
            <span>{{ `${translate('PHONE')} : ${content.phone ? content.phone : '-'}` }}</span>
            <span v-if="user?.roles.includes('COMPANY_ADMIN')">{{
                `${translate('BACKOFFICE.ADVANCED_USER')} : ${checkBox ? translate('YES') : translate('NO')}`
              }}</span>
          </div>
        </div>
        <div class="modal-add-user__profile">
          <span class="modal-add-user__profile__title">{{
              `${translate('BACKOFFICE.USERS.PROFILE_ASSIGNMENTS')} :`
            }}</span>
          <div class="modal-add-user__profile__content">
            <template v-for="(profile, index) in profiles" :key="index">
              <input class="modal-add-user__profile__content__checkbox" type="checkbox"
                     :checked="checkedProfiles(index, profile?.name)"
                     @input="(e) => stateCheckBoxProfile(e, index, profile?.name)">
              <span>{{ profile?.name }}</span>
              <span>{{ profile?.description }}</span>
            </template>
          </div>
        </div>
        <div class="modal-add-user__actions">
          <Button :label="translate('BUTTONS.RETURN')" class="modal-add-user__actions__return"
                  @click="backFirstPage"/>
          <Button :label="translate('BUTTONS.CANCEL')" class="modal-add-user__actions__cancel"
                  @click="cancelAction"/>
          <Button :label="translate('BUTTONS.CONFIRM_AND_CONTINUE')"
                  class="modal-add-user__actions__validate" @click="validateAction"/>
        </div>
      </template>
    </ResultCard>
  </CustomModal>
</template>
<script lang="ts">
import {defineComponent} from "vue";
import Button from "@/components/button/Button.vue";
import TextInput from "@/components/input/TextInput.vue";
import CustomModal from "@/components/modal/CustomModal.vue";
import ResultCard from "@/components/card/ResultCard.vue";
import {translate} from "@/i18n";
import Select from "@/components/select/Select.vue";
import {getRegex, TextInputType} from "@/types/TextInputType";
import PhoneInput from "@/components/input/PhoneInput.vue";
import {ApiUserResponseDto} from "@/apiCalls/login";
import {isEmpty, isNil} from "lodash";

export default defineComponent({
  created() {
    this.supervisorId = this.content?.supervisor?.id;
    this.supervisorName = this.user?.roles.includes('COMPANY_ADMIN') ? this.content?.supervisor?.username : this.user.username;
    this.openModalFlag = this.modalFlag;
  },
  methods: {
    translate,
    cancelAction() {
      this.$emit('cancel-action');
    },
    nextStep() {
      isNil(this.content.phone) ? this.content.phone = null : null;
      isNil(this.content.place) ? this.content.place = null : null;
      isNil(this.content.group) ? this.content.group = null : null;
      this.$emit('next-step', this.content, this.checkBox);
    },
    validateAction() {
      this.$emit('validate-action');
    },
    addModal() {
      this.$emit('add-modal');
    },
    stateCheckBox(e: { target: { checked: boolean } }) {
      this.$emit('update:checkBox', e.target?.checked);
    },
    stateCheckBoxProfile(e: { target: { checked: boolean } }, index: number, name: string) {
      const profiles = this.profilesChecked;
      const index_ = profiles.findIndex((profile:any) => profile.name === name);
      index_ !== -1 ? profiles.splice(index_, 1) : profiles.push({name : name, enabled: true});
      this.$emit('update:profileChecked', profiles);
    },
    backFirstPage() {
      this.$emit('back-first-page');
    },
    checkedProfiles(index: number, name: string) {
      return this.profilesChecked.some((item:any) => item.name === name);
    },
    setSupervisorName(value: string) {
      if(this.content.supervisor === undefined || this.content.supervisor === null) {
        this.content.supervisor = {id: 0, username: value};
      } else {
        this.content.supervisor.username = value;
      }
    },
    setSupervisorId(value: number) {
      if(this.content.supervisor === undefined || this.content.supervisor === null) {
        this.content.supervisor = {id: value, username: ''};
      } else {
        this.content.supervisor.id = value;
      }
    }
  },
  components: {PhoneInput, Select, ResultCard, CustomModal, TextInput, Button},
  props: {
    step: {type: Boolean, default: true},
    checkBox: {type: Boolean, default: false, required: true},
    content: {type: Object as () => ApiUserResponseDto | any, required: true},
    modalFlag: {type: Boolean, default: false},
    supervisors: {type: Array, default: []},
    groups: {type: Array, default: []},
    places: {type: Array, default: []},
    user: {type: Object, required: true},
    title: {type: String, default: ''},
    profiles: {type: Array, default: []},
    profilesChecked: {type: Array, default: []},
    checkBoxProfile: {type: Boolean, default: false}
  },
  watch: {
    content() {
      this.supervisorId = this.content?.supervisor?.id;
      this.supervisorName = this.user?.roles.includes('COMPANY_ADMIN') ? this.content?.supervisor?.username : this.user.username;
    },
    modalFlag() {
      this.openModalFlag = this.modalFlag;
    }
  },
  data: () => ({
    supervisorName: '',
    supervisorId: 0,
    phoneCountry: '+33',
    EMAIL: TextInputType.EMAIL,
    NAME: TextInputType.NAME,
    openModalFlag: false
  }),
  emits: ['update:profileChecked', 'update:checkBox', 'cancel-action', 'next-step', 'validate-action', 'add-modal', 'back-first-page']
})
</script>