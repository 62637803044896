<template>
  <div class="backoffice-user-table">
    <table>
      <thead>
      <tr>
        <th v-for="colsTitle in titles" scope="col" v-bind:key="colsTitle">{{ colsTitle }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in contentColumns" :key="index">
        <td v-for="(value, key) in Object.keys(item).filter(elt => !filterElement.includes(elt))"
            :data-label="key"
            :key="key + index">
          <template v-if="value === 'enabled'">{{ item[value] ? translate('YES') : translate('NO') }}</template>
          <template v-else>{{ item[value] }}</template>
        </td>
        <td>
          <div class="backoffice-user-table__action-column">
            <router-link :to="`/back-office/users/${item.userId}/card`"
                         class="backoffice-user-table__action-column__link backoffice-user-table__action-column__link__see">
              <img src="/images/see.png" alt="see"
                   class="backoffice-user-table__action-column__see"/>
            </router-link>
            <router-link v-if="compareRole(user, item) >= 1" :to="`/back-office/users`"
                         @click="editUserModal(index)"
                         class="backoffice-user-table__action-column__link backoffice-user-table__action-column__link__edit">
              <img src="/images/pen.png" alt="edit"
                   class="backoffice-user-table__action-column__edit"/>
            </router-link>
            <router-link v-if="compareRole(user, item) >= 1" :to="`/back-office/users`"
                         class="backoffice-user-table__action-column__link backoffice-user-table__action-column__link__delete">
              <img src="/images/bin.png" alt="delete"
                   class="backoffice-user-table__action-column__delete" @click="deleteUserModal(index)"/>
            </router-link>
          </div>
        </td>
      </tr>
      <BackOfficeUserModal v-if="contentEditModal" :modal-flag="editUserModalFlag" :content="contentEditModal"
                           :step="firstStepEdit"
                           @validate-action="validateUpdateUser" @next-step="changeStep"
                           @cancel-action="cancelUpdateUser"
                           :supervisors="supervisors" v-model:check-box="checkBoxRoleEditUpdate" :places="places"
                           :groups="groups" :user="user" :title="translate('BACKOFFICE.USERS.USER_MODIFICATION')"
                           :profiles="profiles" v-model:profiles-checked="profilesUser"
                           @back-first-page="changeStep"/>
      <CustomModal v-if="contentEditModal" v-model:open="deleteUserModalFlag"
                   class="backoffice-user-table__delete-modal">
        <ResultCard :title="translate('BACKOFFICE.USERS.USER_DELETION')">
          <div class="backoffice-user-table__delete-modal__summary">
            <span class="backoffice-user-table__delete-modal__summary__title">{{ `${translate('SUMMARY')} :` }}</span>
            <div class="backoffice-user-table__delete-modal__summary__informations">
              <span>{{
                  `${translate('BACKOFFICE.COMPANY')} : ${contentEditModal.companyName ? contentEditModal.companyName : '-'}`
                }}</span>
              <span>{{
                  `${translate('IDENTITY.NAME')} : ${contentEditModal.username ? contentEditModal.username : '-'}`
                }}</span>
              <span>{{ `${translate('EMAIL')} : ${contentEditModal.email ? contentEditModal.email : '-'}` }}</span>
              <span>{{ `${translate('PHONE')} : ${contentEditModal.phone ? contentEditModal.phone : '-'}` }}</span>
              <span>{{
                  `${translate('BACKOFFICE.ADVANCED_USER')} : ${checkBoxRoleEditUpdate ? translate('YES') : translate('NO')}`
                }}</span>
            </div>
          </div>
          <hr>
          <div class="backoffice-user-table__delete-modal__messages">
            <p>{{
                `${translate('BACKOFFICE.USERS.DELETE.MESSAGE_1')} ${contentEditModal.username} ${translate('BACKOFFICE.USERS.DELETE.OF_THE_COMPANY')} ${contentEditModal.companyName}.`
              }}</p>
            <p>{{ `${translate('BACKOFFICE.USERS.DELETE.MESSAGE_2')}` }}</p>
            <p class="backoffice-user-table__delete-modal__action-irreversible">
              {{ `${translate('BACKOFFICE.USERS.DELETE.ACTION_IRREVERSIBLE')}` }}</p>
            <p class="backoffice-user-table__delete-modal__question">{{
                `${translate('BACKOFFICE.USERS.DELETE.QUESTION')}`
              }}</p>
          </div>
          <div class="backoffice-user-table__delete-modal__actions">
            <Button :label="translate('BUTTONS.CANCEL')" class="backoffice-user-table__user-modal__actions__cancel"
                    @click="deleteModal"/>
            <Button :label="translate('BUTTONS.VALIDATE')"
                    class="backoffice-user-table__delete-modal__actions__validate"
                    @click="validateDeletionUser"/>
          </div>
        </ResultCard>
      </CustomModal>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Button from "@/components/button/Button.vue";
import ResultCard from "@/components/card/ResultCard.vue";
import CustomModal from "@/components/modal/CustomModal.vue";
import {translate} from "@/i18n";
import LabelValuePrinter from "@/components/LabelValuePrinter.vue";
import TextInput from "@/components/input/TextInput.vue";
import BackOfficeUserModal from "@/components/backoffice/BackOfficeUserModal.vue";
import {compareRole} from "@/utils/backOffice";
import {ApiUserResponseDto} from "@/apiCalls/login";

export default defineComponent({
  components: {BackOfficeUserModal, TextInput, LabelValuePrinter, CustomModal, ResultCard, Button},
  props: {
    contentColumns: {type: Object, default: {}},
    titles: {type: Object, default: {}},
    actionColum: {type: Object, default: {}},
    filterElement: {type: Array, default: []},
    supervisors: {type: Array, default: []},
    groups: {type: Array, default: []},
    places: {type: Array, default: []},
    user: {type: Object as () => ApiUserResponseDto, required: true},
    profiles: {type: Array, default: []},
    profilesChecked: {type: Array, default: []},
  },
  methods: {
    compareRole,
    translate,
    editUserModal(index: any) {
      this.contentEditModal = {...this.contentColumns[index]};
      this.profilesUser = this.contentEditModal?.profiles?.filter((profile: any) => profile.name !== "ADMIN_ACCESS")?.map((item: any) => {
        return {
          name: item.name,
          enabled: item.enabled
        };
      });
      this.checkBoxRoleEditUpdate = !!(this.contentEditModal?.roles.includes('COMPANY_MANAGER') || this.contentEditModal?.roles.includes('COMPANY_ADMIN'));
      this.roleUpdate = 'COMPANY_USER';
      this.editModal();
    },
    validateUpdateUser() {
      this.changeStep();
      this.editModal();
      if (this.checkBoxRoleEditUpdate) this.roleUpdate = 'COMPANY_MANAGER';
      this.$emit('update-user', this.contentEditModal, this.roleUpdate, this.profilesUser);
      this.$router.push('/back-office/users');
    },
    editModal() {
      this.editUserModalFlag = !this.editUserModalFlag;
    },
    cancelUpdateUser() {
      this.firstStepEdit = true;
      this.editModal();
    },
    deleteUserModal(index: any) {
      this.contentEditModal = {...this.contentColumns[index]};
      this.deleteModal();
    },
    deleteModal() {
      this.deleteUserModalFlag = !this.deleteUserModalFlag;
    },
    validateDeletionUser() {
      this.$emit('delete-user', this.contentEditModal);
      this.deleteModal();
    },
    changeStep() {
      this.firstStepEdit = !this.firstStepEdit;
    }
  },
  data: () => ({
    editUserModalFlag: false,
    contentEditModal: undefined as ApiUserResponseDto | undefined,
    firstStepEdit: true,
    roleUpdate: 'COMPANY_USER' as 'COMPANY_MANAGER' | 'COMPANY_USER',
    checkBoxRoleEditUpdate: false,
    deleteUserModalFlag: false,
    profilesUser: [] as any
  })
})
</script>
